<template>
  <div class="rightfrom">
    <div class="right_center" v-if="defaultnames">
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{defaultnames}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <main>
        <router-view />
      </main>
    </div>
    <div class="empty" v-else style="min-height:745px;color:#5d6066;">
      <h1>欢迎来到个人中心</h1>
    </div>
    <!-- <el-empty description="暂无数据"  style="min-height:745px"></el-empty> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    ...mapState({
      defaultname: (state) => state.userinfo.breadname,
    }),
    defaultnames() {
      console.log(this.defaultname, "defaultname");
      if (!this.defaultname) {
        let aa = window.sessionStorage.getItem("defaultname");
        console.log(aa, "aa");
        if (!aa) {
          return null;
        } else {
          return aa;
        }
      } else {
        return this.defaultname;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rightfrom {
  .right_center {
    nav {
      padding: 10px 20px 10px 10px;
    }
    main {
      // padding: 10px 20px 15px;
      // background-color: #fff;
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>