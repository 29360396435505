<template>
  <div class="userinfo">
    <div class="userinfo_center">
      <!-- <h1>个人中心</h1> -->
      <main>
        <leftsub></leftsub>
        <rightfrom></rightfrom>
      </main>
    </div>
  </div>
</template>
<script>
import leftsub from "./leftsub.vue";
import rightfrom from "./rightfrom.vue";
export default {
  data() {
    return {
      // 角色信息
      // roleInfo: null,
    };
  },
  created() {},
  methods: {},
  computed: {},
  components: {
    leftsub,
    rightfrom,
  },
};
</script>
<style lang="scss" scoped>
.userinfo {
  .userinfo_center {
    width: 100%;
    // width: 1200px;
    // margin: 0 auto;
    main {
      display: flex;
      justify-content: space-between;
      .leftsub {
        width: 200px;
        min-height: 745px;
        background-color: #fff;
        // border: 1px solid #d2d2d2;
      }
      .rightfrom {
        width: calc(100% - 220px);
      }
    }
  }
}
</style>