<template>
  <div class="leftsub">
    <div class="left_center">
      <!-- default-active="1-1"      -->
      <el-menu class="el-menu-vertical-demo" :unique-opened="true" @select="handleSelect" :default-active="defaultindex">
        <div class="left_menu" v-for="item in sublist" :key="item.id">
          <el-submenu :index="item.numIndex" v-if="item.children!==undefined">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span>{{item.content}}</span>
            </template>
            <el-menu-item v-for="items in item.children" :key="items.id" :index="items.numIndex" @click="getname(items.content)">
              <!-- <i class="el-icon-menu"></i> -->
              <span slot="title">{{items.content}}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item :index="item.numIndex" v-else @click="getname(item.content)">
            <!-- <i class="el-icon-menu"></i> -->
            <span slot="title">{{item.content}}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sublist: null,
      //
      defaultindex: null,
    };
  },
  created() {
    // console.log(window.sessionStorage.getItem("defaultindex"), "123456789");
    let aIndex = window.sessionStorage.getItem("defaultindex");
    if (aIndex == null) {
      // console.log("null的位置");
      // this.defaultindex='1-1'
      this.defaultindex = null;
    } else {
      // console.log("NOnull的位置");
      this.defaultindex = aIndex;
    }
    this.handleSelect(this.defaultindex);
    this.querylist();
  },
  methods: {
    // 获取列表权限
    querylist() {
      this.$Http.getAllMenu().then((res) => {
        // console.log(res.data, "获取列表");
        this.sublist = res.data;
      });
    },
    //当前激活的菜单
    handleSelect(index, indexpath) {
      // console.log(index, indexpath, "当前激活的菜单");
      if(!index){
        return
      }
      this.defaultindex = index;
      this.$router.push({
        name: index,
      });
    },
    //
    getname(name) {
      // console.log(name, "当前的名字");
      this.$store.commit("userinfo/editbreadname", name);
      window.sessionStorage.setItem("defaultname", name);
    },
  },
  watch: {
    defaultindex() {
      // console.log(this.defaultindex, "默认的index");
      window.sessionStorage.setItem("defaultindex", this.defaultindex);
    },
  },
};
</script>
<style lang="scss" scoped>
.leftsub {
  .left_center {
  }
}
</style>